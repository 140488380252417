<template>
  <div>
    <div class="printable-page">
      <h4>ใบปิดยอด</h4>

      <b-card>
        <b-button @click="deposit_payment('deposit')" class="mr-1">
          เงินประกันรับ
        </b-button>
        <!-- เงินเข้า ภายใน -->

        <b-button @click="deposit_payment('withdraw')" class="mr-1"
          >เงินประกันออก</b-button
        >
        <b-button @click="income_payment()" class="mr-1">รายรับ</b-button>
      </b-card>

      <!-- <div v-for="(x, index) in payments" :key="index">
        {{ x }} <br /><br />
      </div> -->
    </div>
  </div>
</template>

<script>
import { BCard, BButton, BBadge, BSpinner } from "bootstrap-vue";
export default {
  data() {
    return {
      payments: [],
    };
  },
  components: {
    BCard,
    BButton,
    BBadge,
    BSpinner,
  },
  created() {
    this.load_data();
  },
  methods: {
    income_payment() {
      const today = new Date();
      const formattedDate = today
        .toLocaleDateString("en-GB")
        .replace(/\//g, "-");

      const url = `/account/print-income-payment?date=${formattedDate}`;

      window.open(url, "_blank");
    },
    deposit_payment(side) {
      const today = new Date();
      const formattedDate = today
        .toLocaleDateString("en-GB")
        .replace(/\//g, "-"); // Formats as DD-MM-YYYY

      const url = `/account/print-deposit-payment?date=${formattedDate}&side=${side}`;

      window.open(url, "_blank");
    },
    async load_data() {
      await this.$http({
        method: "POST",
        url: `payment/day-close`,
      })
        .then((x) => {
          this.payments = x.data.data;
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
  },
};
</script>
